import { createAction } from '../common/actionUtils';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetPaymentMethodsPayload,
  SetOrganizationFullPayload,
  SetIsUserLoggedInPayload,
  SetSavedAddressesPayload,
  SetIsLoadingAddressesFromServerPayload,
  SaveAddressToServerPayload,
  ChangeLocationPayload,
  SetIsLoadingLocationFromServerPayload,
  SetLocationsPayload,
  SetIsMAInstalledPayload,
  SetIsRewardInstalledPayload,
  SetIsLocationPickedPayload,
  SetLoadingLocationsSettingsFromServerPayload,
  SetLoyaltyAccountPayload,
  SetLoyaltyRewardsPayload,
  SetLoyaltyProgramPayload,
  SetIsMultiLocationPayload,
  SetLockedDineInLocationPayload,
  SetLoyaltyEarningRulesPayload,
  SetLoadingPaymentMethodsPayload,
  SetSiteLanguage,
  SetExperiments,
  SetEssentialsExperiments,
} from './session.actions.types';
import { SetSignedInstancePayload } from '../platformParams/platformParams.actions.types';

export const setOrganizationFull = createAction<SetOrganizationFullPayload>('SET_ORGANIZATION_FULL');
export const openModal = createAction<OpenModalPayload>('OPEN_MODAL');
export const closeModal = createAction<CloseModalPayload>('CLOSE_MODAL');
export const navigate = createAction<NavigatePayload>('NAVIGATE');
export const saveStateToSessionStorage = createAction('SAVE_ORDER_ITEMS_TO_SESSION_STORAGE');
export const clearSessionStorage = createAction('CLEAR_SESSION_STORAGE');
export const initApp = createAction('INIT_APP');
export const runSideEffectsAfterFirstRender = createAction('HAS_MEMBERS_AREA_APP');
export const setPaymentMethods = createAction<SetPaymentMethodsPayload>('SET_PAYMENT_METHODS');
export const setIsUserLoggedIn = createAction<SetIsUserLoggedInPayload>('SET_IS_USER_LOGGED_IN');
export const setSavedAddresses = createAction<SetSavedAddressesPayload>('SET_SAVED_ADDRESSES');
export const userLoggedIn = createAction('USER_LOGGED_IN');
export const setIsLoadingAddressesFromServer = createAction<SetIsLoadingAddressesFromServerPayload>(
  'SET_IS_LOADING_ADDRESSES_FROM_SERVER',
);
export const saveAddressToServer = createAction<SaveAddressToServerPayload>('SAVE_ADDRESS_TO_SERVER');
export const setSignedInstance = createAction<SetSignedInstancePayload>('SET_SIGNED_INSTANCE');
export const changeLocation = createAction<ChangeLocationPayload>('CHANGE_LOCATION');
export const setIsLoadingLocationFromServer = createAction<SetIsLoadingLocationFromServerPayload>(
  'SET_IS_LOADING_LOCATION_FROM_SERVER',
);
export const setLoadingLocationsSettingsFromServer = createAction<SetLoadingLocationsSettingsFromServerPayload>(
  'SET_IS_LOADING_LOCATIONS_SETTINGS_FROM_SERVER',
);
export const setIsMAInstalled = createAction<SetIsMAInstalledPayload>('SET_IS_MA_INSTALLED');
export const setIsRewardInstalled = createAction<SetIsRewardInstalledPayload>('SET_IS_REWARD_INSTALLED');
export const setLocations = createAction<SetLocationsPayload>('SET_LOCATIONS');
export const setIsLocationPicked = createAction<SetIsLocationPickedPayload>('SET_IS_LOCATION_PICKED');
export const setLoyaltyAccount = createAction<SetLoyaltyAccountPayload>('SET_LOYALTY_ACCOUNT');
export const setLoyaltyRewards = createAction<SetLoyaltyRewardsPayload>('SET_LOYALTY_REWARDS');
export const setLoyaltyProgram = createAction<SetLoyaltyProgramPayload>('SET_LOYALTY_PROGRAM');
export const setLoyaltyEarningRules = createAction<SetLoyaltyEarningRulesPayload>('SET_LOYALTY_EARNING_RULES');
export const goToLoyaltyPage = createAction('GO_TO_LOYALTY_PAGE');
export const setIsMultiLocation = createAction<SetIsMultiLocationPayload>('SET_IS_MULTI_LOCATION');
export const setLockedDineInLocation = createAction<SetLockedDineInLocationPayload>('SET_LOCKED_DINE_IN_LOCATION');
export const setLoadingPaymentMethods = createAction<SetLoadingPaymentMethodsPayload>('SET_LOADING_PAYMENT_METHODS');
export const setSiteLanguage = createAction<SetSiteLanguage>('SET_SITE_LANGUAGE');
export const setExperiments = createAction<SetExperiments>('SET_EXPERIMENTS');
export const setEssentialsExperiments = createAction<SetEssentialsExperiments>('SET_ESSENTIALS_EXPERIMENTS');
