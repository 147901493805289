import { createSelector } from 'reselect';
import Experiments from '@wix/wix-experiments';
import { AppState } from '../createStore';

export const selectShouldCalculateTipFromSubtotal = createSelector(
  (state: AppState) => state.session.experiments,
  (experimentsObject) => {
    if (!experimentsObject) {
      return false;
    }
    const experiments = new Experiments({ experiments: experimentsObject });
    return experiments.enabled('specs.restaurants.CalculateTipFromTheSubTotal');
  },
);
